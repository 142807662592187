<template>
    <div
        class="search"
        :class="{
            'search--no-results': results?._meta?.total == 0,
            'search--results': results?._meta?.total > 0,
        }"
    >
        <Modal
            :id="id"
            :required="false"
            :close="false"
            :show="show"
            @show="focus"
            @showing="resetFilter"
            ref="modal"
            :fade="false"
        >
            <template #header>
                <div class="close">
                    <div class="container">
                        <FormButton
                            class="l-button--icon-only l-button--secondary l-button--outlined close__button"
                            data-bs-dismiss="modal"
                            :data-bs-target="`#${id}`"
                        >
                            <IconsClose />
                        </FormButton>
                    </div>
                </div>
                <div class="d-flex flex-column w-100 h-100">
                    <div class="d-flex align-items-center search__input">
                        <IconsSearch class="search__icon" />
                        <form
                            role="search"
                            action="."
                            @submit.prevent
                            class="search__form"
                        >
                            <input
                                ref="searchInput"
                                type="search"
                                id="search-input"
                                class="equal-base equal-base--tighter"
                                :placeholder="t('search.mask.placeholder')"
                                autocomplete="off"
                                v-model="q"
                                @keyup.enter="redirect"
                            />
                        </form>
                        <div
                            class="search__clear flex-shrink-0"
                            @click="clear"
                            v-show="q"
                        >
                            <IconsClose />
                        </div>
                    </div>
                    <ul
                        v-if="
                            results?._meta?.total > 0 ||
                            (filter?.filter(String).length > 0 &&
                                results?._meta?.filter?.length > 0)
                        "
                        class="search__tags"
                    >
                        <li v-for="item in results?._meta?.filter">
                            <Tag
                                :name="item?.verbose_name"
                                class="tag"
                                :class="{
                                    'tag--checked': filter
                                        .map((f) => f.name)
                                        .includes(item?.name),
                                }"
                                @click="toggleFilter(item)"
                            />
                        </li>
                    </ul>
                </div>
            </template>
            <template #default v-if="results?._meta?.total > 0">
                <template v-for="section in results?.data">
                    <div v-if="section?._meta?.total > 0" class="result">
                        <div class="equal-small-4 result__category">
                            {{ section?._meta?.verbose_name_plural }}
                        </div>
                        <ul class="result__list">
                            <li
                                v-for="hit in section?.results"
                                class="equal-small-2"
                            >
                                <NuxtLink :to="hit?.to" @click="close">
                                    <div
                                        v-html="hit?.name"
                                        class="result__name"
                                    ></div>
                                </NuxtLink>
                            </li>
                        </ul>
                    </div>
                </template>
            </template>
        </Modal>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { useSearchStore } from '@/stores/search';
import { useSeoStore } from '@/stores/seo';

//  --------------------------------------------------------------------------------------------------------------------
//  props + vars
//  --------------------------------------------------------------------------------------------------------------------
const config = useRuntimeConfig();
const { t, locale } = useI18n();
const localeRoute = useLocaleRoute();

const searchStore = useSearchStore();
const seoStore = useSeoStore();
const { q, filter } = storeToRefs(searchStore);
const results = ref(null);

const props = defineProps({
    id: {
        type: String,
        default: 'search-mask',
    },
    show: {
        type: Boolean,
        default: false,
    },
});

const modal = ref(null);
const searchInput = ref(null);

//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const queryFilter = computed(() => {
    const filterResult =
        filter.value?.filter(({ name }) => name).map(({ name }) => name) ||
        results.value?._meta?.filter
            ?.filter(({ name }) => name)
            .map(({ name }) => name) ||
        [];

    if (pageIsMagazine.value && !filterResult.includes('products')) {
        filterResult.push('!products');
    }

    return filterResult.join(',');
});

const headers = computed(() => {
    return [['Accept-Language', locale.value]];
});

const pageIsMagazine = computed(() => {
    return seoStore.pageType.includes('cms.Magazine');
});

//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
function clear() {
    q.value = '';
    filter.value.splice(0);
    results.value = null;
    focus();
}

function resetFilter() {
    filter.value.splice(0);
    results.value = null;
}

function close() {
    q.value = '';
    modal.value.hide();
}

function focus() {
    searchInput.value.focus();
}

function toggleFilter(f) {
    focus();
    const m = filter.value.map((f) => f.name);
    if (m.includes(f.name)) {
        const index = m.indexOf(f.name);
        if (index > -1) {
            filter.value.splice(index, 1);
        }
    } else {
        filter.value.push(f);
    }
}

const { data } = await useFetch(`/search/mask/`, {
    method: 'GET',
    server: false,
    baseURL: config.public.apiBase,
    headers: headers,
    query: { q, filter: queryFilter },
    watch: [q, queryFilter],
    immediate: false,
});

async function redirect() {
    modal.value.hide();
    navigateTo(
        localeRoute({
            name: 'search',
            query: { q: q.value, filter: queryFilter.value || undefined },
        }),
    );
}

//  --------------------------------------------------------------------------------------------------------------------
//  watchers
//  --------------------------------------------------------------------------------------------------------------------
watch(data, (newData) => {
    results.value = newData;
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.close {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    z-index: 1200;

    @include media-breakpoint-down(560) {
        top: unset;
        bottom: 20px;
        height: auto;
    }

    .container {
        height: 100%;
        display: flex;
        align-items: flex-end;
    }

    &__button {
        margin: auto 52px auto auto;

        @include media-breakpoint-down(560) {
            position: absolute;
            right: 50%;
            margin: 0 35px 5px 0;
        }
        &:hover,
        &:focus,
        &:active {
            background-color: $color-plain-grey-30 !important;
        }
    }
}

.search {
    transition: 0.5s ease;

    &--no-results {
        :deep(.modal-content-box) {
            border-radius: 25px !important;
            transition: 0.5s ease;
        }
    }

    &--results {
        :deep(.modal-content-box) {
            border-radius: 25px !important;
            transition: 0.5s ease;
        }

        :deep(.modal-header) {
            height: 100% !important;
        }
    }

    :deep(.layout__row) {
        height: auto !important;
    }

    :deep(.layout__col) {
        width: 800px;
    }

    :deep(.modal-dialog) {
        align-items: start;
        height: 200px;

        @include media-breakpoint-down(560) {
            padding: 0 !important;
        }
    }

    :deep(.modal-content) {
        max-height: unset;
    }
    @include media-breakpoint-down(560) {
        :deep(.modal-content) {
            max-height: calc(100dvh - 120px);
        }
    }

    :deep(.modal-content-box) {
        max-height: 415px !important;
        margin-top: 220px;
        margin-bottom: 220px;
        border-radius: 25px !important;
        min-height: 50px;

        @include media-breakpoint-down(560) {
            max-height: unset !important;
            margin-top: 40px;
            margin-bottom: 20px;
        }
    }

    :deep(.modal-header) {
        padding: 0 !important;
        // height: 30px;
    }

    :deep(.modal-body) {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    &__icon {
        fill: $color-disabled;
        width: 18px;
        height: 18px;
        margin-left: 20px;
        margin-right: 10px;
    }

    &__input {
        width: 100%;
        position: relative;

        input {
            width: 100%;
            color: $color-light;
            outline: unset;
            background-color: unset;
            border: 0;
            text-transform: none;
            height: 30px;
        }
    }

    &__clear {
        background-color: $color-plain-grey-10;
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;
        margin-left: 20px;
        border-radius: 100%;
        border: 1px solid $color-plain-grey-30;
        position: absolute;
        right: 0;
        top: 0;

        cursor: pointer;
        &:hover,
        &:focus,
        &:active {
            background-color: $color-plain-grey-20;
            border: none;
        }
        svg {
            margin: auto;
            fill: #999999;
            width: 12px;
            height: 12px;
        }
    }

    &__tags {
        margin: 10px -10px;
        padding: 15px 30px 0 30px;
        list-style: none;
        display: flex;
        flex-wrap: wrap;

        border-top: 1px solid $color-dark-01;
        border-bottom: 1px solid $color-dark-01;

        li {
            &:not(:last-child) {
                margin-right: 15px;
            }

            margin-bottom: 15px;
        }
    }
}

.tag {
    background-color: $color-plain-grey-15;
    &--checked {
        border: 1px solid #808080;
        background-color: $color-dark-01;
        &:hover,
        &:focus,
        &:active {
            background-color: $color-plain-grey-15 !important;
        }
    }
    &:hover,
    &:focus,
    &:active {
        background-color: $color-plain-grey-20;
    }
}

.result {
    padding: 15px 0;

    &:first-child {
        padding-top: 5px;
    }

    &:not(:last-child) {
        border-bottom: 1px solid $color-dark-01;
    }

    &__category {
        padding-top: 15px;
        padding-bottom: 10px;
        color: $color-disabled;
        font-weight: bold;
        text-transform: uppercase;
    }

    &__list {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            padding: 15px 0;

            a {
                outline: none;
                text-decoration: none;
            }
        }
    }

    &__name {
        font-weight: bold;

        :deep(mark) {
            font-weight: normal;
            background-color: unset;
            color: #999999;
        }
    }
}

:deep(.highlight) {
    font-weight: normal;
    color: $color-disabled;
}
.search__form {
    width: 100%;
}
</style>
